.h-container{
  justify-content: space-between;
  padding: 1rem 8rem 0rem 16rem; 
}
.h-wrapper{
       color:white;
 }
 .h-menu{
    gap: 2rem;
 }
 .h-menu a{
    color: rgb(177, 172, 172);
    padding-left: 0.5rem;
    text-decoration: none;
 }
 .h-menu > *:hover{
    cursor: pointer;
 }
 .logo{
   font-size: 32px;
   font-family: sans-serif;
   font-weight: 900;
 }
 .menu_icon{
   display: none;
 }



 @media only screen and (-webkit-min-device-pixel-ratio: 2.625) {

.menu_icon{
  display: block;
    width: 2rem;
    height: auto;
    position: absolute;
    top: 2rem;
    left: 1rem;

}
.h_menu2{
   gap: 2rem;
   position: absolute;
   top: 5rem;
    left: 1rem;
   display: flex;
   flex-direction: column;
   background-color: white;
   border-radius: 10px;
   align-items: flex-start;
   box-shadow:0px 11px 24px rgba(0, 0,0,0.05);
   padding: 2rem;
   margin: -1rem 2rem 1rem 2rem;
   z-index: 1;
   transition: all 300ms ease-in;
}
.padam{
   color:black;
}
 }