
*{
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins', sans-serif;
}
.paddings{
  padding: 1.4rem;
}
.flexCenter{
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.inner-width {
  width: 100%;
}
.image_container{
  margin-left: 2rem;
  height: 28.3rem;
  width: 26rem;
}
.button{
  font-weight:500;
  padding: 0.6rem 1.4rem;
  color: white;
  background-color: #0073cf;
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
  text-decoration: none;
}
.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.orangeText{
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}
.secondaryText{
  color: rgb(140 139 139);
  font-size: 0.2rem;
}
.primaryText{
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}
.secondaryText2{
  font-size: 1.2rem;
  color: rgb(140 139 139);
  padding-left: 59px;
}

@media (min-width : 1536px){
  .inner-width{
    max-width:1280px;
    margin:auto;
  }
}

@media (min-width : 64px){
  .paddings{
   padding: 4rem;
  }
}

@media (min-width :1280px),(min-width:768px){
  .inner-width{
    padding: 2rem;
  }
} 

