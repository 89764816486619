.r_card{
    gap:0.6rem;
    display: flex;
    flex-direction: column;
    justify-content:center;
    padding: 0rem;
    border-radius: 20px;
    max-width: max-content;
    transition: all 300ms ease-in;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 2rem;
    
}
.r_card>img{
    width: 84%;
    max-width: 15rem;
    border-radius: 15px;
    height: 8rem;
}

.r_card>:nth-child(2){
    font-size: 1.2rem;
    font-weight: 600;
}
.r_card>:nth-child(3){
    font-size: 19px;
}
.r_card>:nth-child(4){
    font-size: 0.7rem;
    width: 15rem;
}
.r_card:hover{
    scale: 1.025;
    cursor: pointers;
    height: 14.5rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;

}
.swiper-slide{
    margin-right: 23rem;
}
.r_buttons{
    position: absolute;
    top: 0rem;
    right: 4rem;
    gap: 1rem;    
}
.r_buttons >button{
font-size: 1.2rem;
color: blue;
border: none;
border-radius: 5px;
cursor: pointer;
background-color: white;
width: 1.5rem;
padding: 0.2rem 0.8rem;
}
.r_buttons>:nth-child(1){
    background-color: #EEEEFF;
}
.r_buttons>:nth-child(2){
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
}
.swiper{
    padding-top: 4rem;
}


@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {

    .r_card > img {
        width: 18rem;
        max-width: 60rem;
        border-radius: 15px;
        height: 12rem;
      }

}