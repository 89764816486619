.c_left{
    display: flex;
    flex-direction: column;
}
.c_wrapper{
    margin-left: 2rem;
}
.c_container{
    justify-content: space-between;
    flex-direction: row;
    display: flex;
}
.c_container>div{
flex:4rem;
}
.c_right{
    width: 100%;
    max-width: 30rem;
}
.contact_head{
    margin-left: 9cqb;
}
.c_left{
    gap: 0.5rem;
    margin-right: 7rem;
}
.flexColStart{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.contactModes{
    margin: 2rem 6rem 1rem 6rem;
}
.contactModes .row{
    gap: 1.5rem;
}
.mode{
    width: 10rem;
    padding: 1rem;
    border: 0.8px oslid rgba(128,128, 128, 0.142);
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
    border: 1px solid rgb(218, 212, 212);
    margin-bottom: 1rem;
}
.mode .button{
    width: 100%;
    max-width: 7rem;
    background: lightblue;
    color: blue;
    font-style: 0.9rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}              
.flexStart{
    display:flex;
    align-items: start;
}
.details{
    margin-left:13px;
}
.mode .details .primaryText{
    font-size: 1rem;
    font-weight: 600;
}
.number{
    font-size: 1rem;
}
.mode:hover{
    scale:1.1;
    box-shadow: 0px 10px rgb(242, 245, 247);
}
.mode .button:hover{
    background: blue;
    color: white;
    scale: 0.8;
}
.textfire{
    color: blue;
}
.icon svg {
    fill: rgb(111, 111, 223);
    cursor: pointer;
  }