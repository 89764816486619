.secondaryText2{
    font-size: 1.2rem;
    color: rgb(140 139 139);
    padding-left: 43px;
}
.wantedText{
    font-size: 1.2rem;
    color:rgb(192, 180, 180)
   
}
.image_holder{
    height: 28.3rem;
  width: 26rem;
}
.v_container .image_container{
    border: 8px solid rgb(232 232 232 /93%);
}
.v_container >div{
    flex:1;
}
.v_right{
    gap:0.5rem;
}
.accordion{
    margin-top: 2rem;
    border: none;
}  
.accordionItem{
    background:whitesmoke;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 1rem;
    margin-right: 1rem;
}

.accordionButton{
       background: white;
       width: 100%;
       justify-content: space-between;
       cursor: pointer;
       /* background-color: #fbf5f5; */
}
.icon{
    padding: 10px;
    background:#eeeeff;
    border-radius: 5px;
}
.icon svg{
    fill: rgb(111, 111, 223);
}
.accordionButton .primaryText{
    font-size: 1.1rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {

    .image_holder {
        height: 27.3rem;
        width: 22rem;
        margin-right: 2.1rem;
        margin-top: -6rem;
      }
}
