.c_container{
    gap: 4rem;
    padding-top: 0.5rem;
}
.c_container>img{
    width: 8rem;
    color: grey;
    padding-left: 96px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
    .c_container > img {
        width: 7rem;
        color: grey;
        padding-left: 0;
      }
      .secondaryText3{
        color: rgba(81, 77, 77, 0.59);
      }

}