.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
}
.hero_container{
    display: flex;
     align-items: center;
     justify-content: space-around;
     gap: 0rem;
     padding-bottom: 0px;
}
/* right side */
.image_container{
    height: 24.3rem;
    width: 21rem;
    border-radius: 15rem 15rem 0  0;
    overflow: hidden;
    border: 8px solid rgba(255,255,255,0.12);
}
.image_container>img{
    width: 100%;
    height: 100%;
}
/* left side */
.orange_circle{
    height: 4rem;
    width: 4rem;
    background-color: orange;
    border-radius: 999px;
    position: absolute;
    right:69.6%;
    top: 7%;
    
}
.hero_title{
    position:relative;
    margin-bottom: 1rem;
}
.hero_title>h1{
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3rem;
}
.hero_desc{
    margin-bottom: 2rem;
}
.search_bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120,120,120,0.37);
    padding:0.5rem 1rem;
    justify-content: space-between;
    width:91%;
}
.search_bar>input{
    border:none;
    outline: none;
    margin-right: 0.3rem;
}
.stats {
    width: 100%;
    justify-content: space-betweenfa;
    margin-top: 24px;
}
.stat>:nth-child(1){
    font-size: 2rem;
    padding-left: 32px;
}
.stat>:nth-child(1)>:last-child{
    color:orange;
}
.stat>:last-child{
    font-size: 12px;
    padding-left: 30px;
    color: rgb(134, 131, 131);
}
.secondary_text{
    color: rgb(134, 131, 131);
}

/* media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
    .header_image{
      margin-left: 0rem;
    }
    .hero_container {
        display: flex;
        flex-direction: column;
      }
      .c_container > img {
        margin-top: 3rem;
      }
      .c_container {
        margin-left: -3rem;
      }
      .residencies {
        margin-left: -1rem;
      }
      .contact_head {
        margin-left: 11rem;
      }
        .c_wrapper {
            display: flex;
            flex-direction: column;
          }
          .secondtext {
            max-width: 23rem;
            width: 18rem;
            margin-left: 3rem;
          }
        .contactModes .row {
          gap: 1.5rem;
        
        }
        .flexStart {
          display: flex;
        flex-direction: column;
        }
        .mode {
            margin-left: 4rem;
          }
        .contactImage{
            margin-left: -4rem;
        }
        .inner_container {
            margin: 0rem 1rem 1rem 1rem;
            height: 17rem;
            width: 23rem;
          }
          .secondaryText3 {
            padding-left: 7px;
          }
          .f_container {
            display: flex;
            flex-direction: column;
          }
          .orange_circle{
            position: relative;
            top:2rem;
            left: 11rem;
          }
          .search_bar {
            width: 23rem;
            padding-left: 0rem;
            padding-right: 0rem;
          }
          
} 