.flexcolCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.inner_container{
    gap: 1.5rem;
    background: #4161df;
    padding:2rem;
    border-radius:10px;
    border: 6px solid #5d77d6;
    text-align: center;
    margin: 0rem 13rem 1rem;
    height: auto;
}
.inner_container .primaryText{
    color: white;
    font-weight: 600;
}
.secondaryText3 {
    font-size: 1.2rem;
    color: rgb(255,255, 255,0.587); 
    padding-left: 43px;
    margin-top: -30px;
  }
  .inner_container .button a{
    text-decoration: none;
  }
  .inner_container .button {
    background: #758ef2;
    box-shadow:rgb(245, 243, 243) ;
    border: 1px solid white;
    border-radius: 10px;
  }
  .f_left img{
    height: 2rem;
    width: 2rem;
  }
  .f_container{
justify-content: space-between;
margin: 7rem 6rem 3rem 13rem;
  }
  .f_left{
    gap: 1rem;
  }
  .f_menu{
    gap: 0.5rem;
    margin-top: 1.5rem;
    font-weight: 500;

  }
  .secondaryText4 {
    font-size: 1.2rem;
    color: rgb(140 139 139);
    padding-left: 0px;
  }
.textFire{
  font-size: 1.2rem;
  color: rgb(255,255, 255,0.587);
  padding-left: 43px;
  margin-top: -30px;
}

  @media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
    .inner_container {
      margin: 0rem 1rem 1rem 1rem;
      height: 17rem;
      width: 19rem;
    }
    .textFire{
      font-size: 1.2rem;
      color: rgb(255,255, 255,0.587);
      padding-left: 43px;
      margin-top: -30px;
    }

  }